import React, { useCallback, useState } from 'react';
import cls from 'classnames';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import {
  groupsCreateGroupClick,
  groupsLivesiteClick,
  groupsSearchForGroup,
} from '@wix/bi-logger-groups/v2';

import { Search as SearchIcon } from '@wix/wix-ui-icons-common/on-stage';

import {
  selectCanCreateGroup,
  selectCanCreateGroupRequests,
  selectGroups,
  selectIsLoggedIn,
} from 'store/selectors';
import { EGroupsNamespace } from 'store/groups/constants';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';
import { useBiParams } from 'common/hooks/useBiParams';

import styleParams from 'Groups/stylesParams';
import settingsParams from 'Groups/settingsParams';

import { Box } from 'wui/Box';
import { Button } from 'wui/Button';
import { Stack } from 'wui/Stack';
import { TextField } from 'wui/TextField';
import { Typography } from 'wui/Typography';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Wire } from 'wui/Wire';
import { Divider } from 'wui/Divider';

import { EmptyGroupList } from '../../../EmptyGroupList';
import { GroupList, GroupListSkeleton } from '../GroupList';

import classes from './Groups.scss';

interface IProps extends React.ComponentProps<typeof Box> {
  wired?: boolean;
}

export function Groups(props: IProps) {
  const { wired, className, ...rest } = props;
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const bi = useBi();
  const biParams = useBiParams();

  const styles = useStyles();
  const settings = useSettings();

  const { groups$, application$ } = useController();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const joined = useSelector(selectGroups(EGroupsNamespace.JOINED));
  const suggested = useSelector(selectGroups(EGroupsNamespace.SUGGESTED));
  const canCreateGroup = useSelector(selectCanCreateGroup);
  const canCreateGroupRequests = useSelector(selectCanCreateGroupRequests);

  const loading =
    joined.status.fetch?.loading || suggested.status.fetch?.loading;

  const title = styles.get(styleParams.sidebarTitleFont);
  const sectionTitleAlignment = settings.get(settingsParams.headerAlignment);

  const [query, setQuery] = useState('');

  const queryGroups = useCallback(
    debounce((title: string) => {
      bi.report(
        groupsSearchForGroup({
          origin: 'sidebar_livesite',
        }),
      );

      groups$.query({
        title,
        namespace: EGroupsNamespace.SUGGESTED,
        partition: EGroupsNamespace.SUGGESTED,
      });

      groups$.query({
        title,
        namespace: EGroupsNamespace.JOINED,
        partition: EGroupsNamespace.JOINED,
      });
    }, 500),
    [],
  );

  useDidUpdate(() => {
    queryGroups(query);
  }, [query]);

  return (
    <Box
      gap="SP6"
      height="100%"
      direction="vertical"
      paddingTop={isMobile ? 'SP0' : 'SP5'}
      paddingBottom={isMobile ? 'SP0' : 'SP5'}
      className={cls(classes.root, className, { [classes.wired]: props.wired })}
      {...rest}
    >
      <Wire
        cssVarName="showSearchSorting"
        legacyFallback={settings.get(settingsParams.showSearchSorting)}
      >
        <Box
          direction="vertical"
          paddingLeft={isMobile ? 'SP0' : 'SP5'}
          paddingRight={isMobile ? 'SP0' : 'SP5'}
        >
          <TextField
            wired={wired}
            value={query}
            withClearButton
            onClear={handleReset}
            prefix={<SearchIcon />}
            placeholder={t('groups-web.search.placeholder')}
            onChange={handleQueryChange}
          />
        </Box>
      </Wire>

      <nav
        className={classes.content}
        aria-label={t('groups-web.group-list.aria-label')}
      >
        <Show if={loading}>
          <GroupListSkeleton wired={wired} />
        </Show>

        <Hide if={loading}>
          <Stack
            direction="vertical"
            gap={isMobile ? 'SP4' : 'SP6'}
            separator={
              <Wire
                cssVarName="showGroupsToJoin"
                legacyFallback={settings.get(settingsParams.showGroupsToJoin)}
              >
                <Divider inset={!isMobile} bw={isMobile} />
              </Wire>
            }
          >
            <Show if={isLoggedIn}>
              <Box
                as="section"
                direction="vertical"
                gap={isMobile ? 'SP4' : 'SP6'}
                paddingLeft={isMobile ? 'SP0' : 'SP5'}
                paddingRight={isMobile ? 'SP0' : 'SP5'}
              >
                <Typography
                  variant="p2-16"
                  as={title.htmlTag as React.ElementType}
                  className={!isMobile ? classes.title : undefined}
                  align={
                    sectionTitleAlignment as React.CSSProperties['textAlign']
                  }
                >
                  {t('groups-web.tabs.my-groups')}
                </Typography>
                <GroupList
                  wired={wired}
                  groups={joined.groups}
                  meta={joined.meta}
                  data-hook="my-groups"
                  status={joined.status}
                  onRetry={handleJoinedRetry}
                  onLoadMore={handleJoinedLoadMore}
                  emptyState={
                    <EmptyGroupList
                      bw={isMobile}
                      variant="section"
                      title={undefined}
                      filters={{ title: query }}
                      data-type="my-groups-empty"
                      className={classes.emptyState}
                      partition={EGroupsNamespace.JOINED}
                    />
                  }
                />
              </Box>
            </Show>
            <Show if={!isLoggedIn || Boolean(suggested.meta.total)}>
              <Wire
                cssVarName="showGroupsToJoin"
                legacyFallback={settings.get(settingsParams.showGroupsToJoin)}
              >
                <Box
                  as="section"
                  direction="vertical"
                  gap={isMobile ? 'SP4' : 'SP6'}
                  paddingLeft={isMobile ? 'SP0' : 'SP5'}
                  paddingRight={isMobile ? 'SP0' : 'SP5'}
                >
                  <Typography
                    variant="p2-16"
                    as={title.htmlTag as React.ElementType}
                    className={!isMobile ? classes.title : undefined}
                    align={
                      sectionTitleAlignment as React.CSSProperties['textAlign']
                    }
                  >
                    {t('groups-web.tabs.suggested-groups')}
                  </Typography>

                  <GroupList
                    wired={wired}
                    groups={suggested.groups}
                    meta={suggested.meta}
                    status={suggested.status}
                    onRetry={handleSuggestedRetry}
                    onLoadMore={handleSuggestedLoadMore}
                    emptyState={
                      <EmptyGroupList
                        bw={isMobile}
                        title={undefined}
                        variant="section"
                        data-type="suggested-groups-empty"
                        filters={{ title: query }}
                        className={classes.emptyState}
                        partition={EGroupsNamespace.SUGGESTED}
                      />
                    }
                  />
                </Box>
              </Wire>
            </Show>
          </Stack>
        </Hide>
      </nav>
      <Show if={canCreateGroup || canCreateGroupRequests}>
        <Box
          className={classes.actions}
          paddingLeft={isMobile ? 'SP0' : 'SP5'}
          paddingRight={isMobile ? 'SP0' : 'SP5'}
        >
          <Wire
            cssVarName="showCreateGroupButton--inline-block"
            legacyFallback={settings.get(settingsParams.showCreateGroupButton)}
          >
            <Button outlined fullWidth onClick={openCreateGroupDialog}>
              {t('groups-web.btn.create-new')}
            </Button>
          </Wire>
        </Box>
      </Show>
    </Box>
  );

  function openCreateGroupDialog() {
    bi.report(
      groupsCreateGroupClick({
        origin: 'new_layout_sidebar',
      }),
    );
    application$.showDialog({ dialog: 'createGroup' });
  }

  function handleQueryChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
  }

  function handleReset() {
    setQuery('');
  }

  function handleJoinedLoadMore() {
    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreenWithTab(EGroupsNamespace.JOINED),
        button_name: 'show_more_groups',
      }),
    );
    groups$.query({
      title: query,
      offset: joined.groups.length,
      namespace: EGroupsNamespace.JOINED,
      partition: EGroupsNamespace.JOINED,
    });
  }

  function handleSuggestedLoadMore() {
    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreenWithTab(EGroupsNamespace.SUGGESTED),
        button_name: 'show_more_groups',
      }),
    );
    groups$.query({
      title: query,
      offset: suggested.groups.length,
      namespace: EGroupsNamespace.SUGGESTED,
      partition: EGroupsNamespace.SUGGESTED,
    });
  }

  function handleJoinedRetry() {
    groups$.query({
      title: query,
      namespace: EGroupsNamespace.JOINED,
      partition: EGroupsNamespace.JOINED,
    });
  }

  function handleSuggestedRetry() {
    groups$.query({
      title: query,
      namespace: EGroupsNamespace.SUGGESTED,
      partition: EGroupsNamespace.SUGGESTED,
    });
  }
}

Groups.displayName = 'Groups';
